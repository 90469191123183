import React, {useEffect, useState} from 'react';
import QrCodeComponent from '../components/QrCode/QrCode'
import Spinner from '../components/Spinner/Spinner';
import https from '../config/https';
import { useData } from '../DataContext';
import { ToastContainer, toast } from 'react-toastify';

function QrPages() {
  const { data, clearData } = useData();
  const [isLoading, setIsLoading] = useState(false);
  const[urlData, setUrlData] = useState([])
  useEffect(()=>{
    const getDashBoardLinks = async() => {
      setIsLoading(true);
      try {
        const response = await https.get(`/dash/links`); 
        if(response?.data){
          if(data){
            setUrlData(data);
            clearData();
          } else {
            setUrlData(response.data)
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showToastMessageError("No links found! Create a couple of links")
      }
    }
    getDashBoardLinks()
  },[])

  
  const showToastMessageError = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <div>
        {isLoading === true ? <Spinner />: <QrCodeComponent url={urlData} />}
    </div>
  );
}

export default QrPages;
