import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import https from '../../config/https';

import Spinner from '../Spinner/Spinner';
import { Typography, CircularProgress } from '@mui/material';
import axios from 'axios';

const RedirectUrl = () => {
  const { param } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `/dash/links/${param}`
        const response = await https.getUri({url}); 
        window.location = response
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [param]);

  return (
    <div>
        {isLoading === true ? <Spinner />: <div></div>}
    </div>
  );
}

export default RedirectUrl;
