import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Paper, Typography} from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function TableHistory({tableData}) {
    const rows = tableData;
    const copy_short_url = (url) => {
        try {
          copyToClipboard(url);
      } catch(error) {
          console.error("alert error: ", error);
      }
        
    }
    async function copyToClipboard(textToCopy) {
        try {
          await navigator.clipboard.writeText(textToCopy);
          alert('Text successfully copied');
        } catch (err) {
          console.error('Unable to copy to clipboard', err);
        }
    }

    const download_qr_code = (img_url) => {
        fetch(img_url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/png',
      },
    })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `qr_code_` + Date().toLocaleString() + '_.png'
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  }

  return (
    <TableContainer component={Paper}>
      {rows.length === 0 ? (
        <Typography variant="h6" align="center" sx={{ fontWeight: 'bold', mt: 4, mb:4 }}>
        No data found
      </Typography>
      ) : (
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>#</StyledTableCell>
            <StyledTableCell sx={{ maxWidth: 300 }}>Original URL</StyledTableCell>
            <StyledTableCell>Shorten URL</StyledTableCell>
            <StyledTableCell>QR Code</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell>{index+1}</StyledTableCell>
              <StyledTableCell sx={{ maxWidth: 300 }}>{row.original_url}</StyledTableCell>
              <StyledTableCell>
              <span Link={row.short_url} target="_blank">{row.short_url}</span>
                <br />
                <button onClick={() => copy_short_url(row.short_url)} class="text-emerald-500 bg-transparent border border-solid border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                    Copy
                </button><br/>
                {/* FEAT:BTN Added => OPEN IN NEW TAB ADDED */}
                <button onClick={() => {
                  // console.log(":row.short_url", row.short_url)
                  window.open(row.short_url,"_blank","noopener noreferrer")
                  }} class="text-emerald-500 bg-transparent border border-solid border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                    Open in New Tab
                </button>
              </StyledTableCell>
              <StyledTableCell>
              <img src={row.qr_code} width="150" height="150" alt="qr_code"></img>
                <button type="button" onClick={() => download_qr_code(row.qr_code)} class="py-2 px-4 mr96 rounded inline-flex items-center">
                <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                <span>Download</span>
                </button> 
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      )}
    </TableContainer>
  );
}
