import React, { useState, useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import https from '../config/https';
import Spinner from '../components/Spinner/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";

function Contact () {
    const siteKey = process.env.REACT_APP_SITE_KEY;
    const recaptcha = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        message: '',
      });

      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      };

      const showToastMessageError = (msg) => {
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      };

      const showToastMessageSuccess = (msg) => {
        toast.success(msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      };

      const handleSubmit = async(e) => {
        e.preventDefault();
        if (!validateEmail(formData.email)) {
          setEmailError('Invalid Email! Please enter a right email');
          return;
        }
        setEmailError('');
        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) {
          showToastMessageError("Please verify the reCAPTCHA!");
          return;
        }
        let data = {
          name: formData.fullName,
          email: formData.email,
          phone: formData.phone,
          message: formData.message
        }
        setIsLoading(true);
        try {
          const response = await https.post('/contact-form', data); 
          if(response.data){
            showToastMessageSuccess(response?.data?.detail)
          }
          setFormData({
            fullName: '',
            email: '',
            phone: '',
            message: '',
          });          
          setIsLoading(false);
        } catch (error) {
          showToastMessageError(error?.response?.data?.detail)
        }
        setIsLoading(false);
      };
    

    return (
        <HelmetProvider>
        <Helmet>
        <title>Contact page of snipurl.in </title>
        <meta name="title" content="Snipurl.in Contact Page"/>
        <meta name="description" content="SnipURL offers adfree and simple-to-use URL shortening service, with QR codes, Link in Bio and more!"/>
        <meta name="keywords" content="Contact page, urlshortner, tinyurl, free url shortner, snipurl.in, qr code url short, bitly"/>
        <meta name="robots" content="index, follow"/>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
        <meta name="language" content="English"/>
       </Helmet>
    <div>
    {isLoading === true ? <Spinner />: <div></div>}
        <section className="bg-white py-20 lg:py-[120px] overflow-hidden relative z-10">
        <div className="container">
            <div className="flex flex-wrap lg:justify-between -mx-4">
                <div className="w-full lg:w-1/2 xl:w-6/12 px-4">
                    <div className="max-w-[570px] mb-12 lg:mb-0">
                    <span className="block mb-4 text-base text-primary font-semibold">
                    Contact Us
                    </span>
                    <h2
                        className="
                        text-dark
                        mb-6
                        uppercase
                        font-bold
                        text-[32px]
                        sm:text-[40px]
                        lg:text-[36px]
                        xl:text-[40px]
                        "
                        >
                        GET IN TOUCH WITH US
                    </h2>
                    <p className="text-base text-body-color leading-relaxed mb-5">
                    We value your feedback and are here to assist you with any questions or concerns you may have.
                    </p>
                    <p className="text-base text-body-color leading-relaxed mb-5">
                    Please don't hesitate to get in touch with us. 
                    Your satisfaction and engagement are of utmost importance to us.
                    </p>

                    </div>
                </div>
                <div className="w-full lg:w-1/2 xl:w-5/12 px-4">
                    <div className="bg-white relative rounded-lg p-8 sm:p-12 shadow-lg">
                    <form action="mailto:mail.snipurl@gmail.com" method="post" enctype="text/plain">
                        <div className="mb-6">
                            <input
                                type="text"
                                placeholder="Your Name"
                                required
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                className="
                                w-full
                                rounded
                                py-3
                                px-[14px]
                                text-body-color text-base
                                border border-[f0f0f0]
                                outline-none
                                focus-visible:shadow-none
                                focus:border-primary
                                "
                                />
                        </div>
                        <div className="mb-6">
                            <input
                                type="email"
                                placeholder="Your Email"
                                required
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="
                                w-full
                                rounded
                                py-3
                                px-[14px]
                                text-body-color text-base
                                border border-[f0f0f0]
                                outline-none
                                focus-visible:shadow-none
                                focus:border-primary
                                "
                                />
                                {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                        </div>
                        <div className="mb-6">
                            <input
                                type="tel"
                                placeholder="91-9456784125"
                                pattern="[0-9]{2}-[0-9]{10}"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className="
                                w-full
                                rounded
                                py-3
                                px-[14px]
                                text-body-color text-base
                                border border-[f0f0f0]
                                outline-none
                                focus-visible:shadow-none
                                focus:border-primary
                                "
                                />
                        </div>
                        <div className="mb-6">
                            <textarea
                                rows="6"
                                placeholder="Your Message"
                                required
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                maxLength={500}
                                className="
                                w-full
                                rounded
                                py-3
                                px-[14px]
                                text-body-color text-base
                                border border-[f0f0f0]
                                resize-none
                                outline-none
                                focus-visible:shadow-none
                                focus:border-primary
                                "
                                ></textarea>
                        </div>
                        <ReCAPTCHA ref={recaptcha} sitekey={siteKey} />
                        <div className='pt-4'>
                            <button
                                type="submit"
                                value="Send"
                                className="
                                w-full
                                text-white
                                bg-blue-500
                                rounded
                                border border-primary
                                p-3
                                transition
                                hover:bg-opacity-90
                                "
                                onClick={handleSubmit}
                                >
                            Send Message
                            </button>
                        </div>
                    </form>
                    <div>
                        <span className="absolute -top-10 -right-9 z-[-1]">
                            <svg
                                width="100"
                                height="100"
                                viewBox="0 0 100 100"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
                                fill="#3056D3"
                                />
                            </svg>
                        </span>
                        <span className="absolute -right-10 top-[90px] z-[-1]">
                            <svg
                                width="34"
                                height="134"
                                viewBox="0 0 34 134"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <circle
                                cx="31.9993"
                                cy="132"
                                r="1.66667"
                                transform="rotate(180 31.9993 132)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="117.333"
                                r="1.66667"
                                transform="rotate(180 31.9993 117.333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="102.667"
                                r="1.66667"
                                transform="rotate(180 31.9993 102.667)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="88"
                                r="1.66667"
                                transform="rotate(180 31.9993 88)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="73.3333"
                                r="1.66667"
                                transform="rotate(180 31.9993 73.3333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="45"
                                r="1.66667"
                                transform="rotate(180 31.9993 45)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="16"
                                r="1.66667"
                                transform="rotate(180 31.9993 16)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="59"
                                r="1.66667"
                                transform="rotate(180 31.9993 59)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="30.6666"
                                r="1.66667"
                                transform="rotate(180 31.9993 30.6666)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="1.66665"
                                r="1.66667"
                                transform="rotate(180 31.9993 1.66665)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="132"
                                r="1.66667"
                                transform="rotate(180 17.3333 132)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="117.333"
                                r="1.66667"
                                transform="rotate(180 17.3333 117.333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="102.667"
                                r="1.66667"
                                transform="rotate(180 17.3333 102.667)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="88"
                                r="1.66667"
                                transform="rotate(180 17.3333 88)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="73.3333"
                                r="1.66667"
                                transform="rotate(180 17.3333 73.3333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="45"
                                r="1.66667"
                                transform="rotate(180 17.3333 45)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="16"
                                r="1.66667"
                                transform="rotate(180 17.3333 16)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="59"
                                r="1.66667"
                                transform="rotate(180 17.3333 59)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="30.6666"
                                r="1.66667"
                                transform="rotate(180 17.3333 30.6666)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="1.66665"
                                r="1.66667"
                                transform="rotate(180 17.3333 1.66665)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="132"
                                r="1.66667"
                                transform="rotate(180 2.66536 132)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="117.333"
                                r="1.66667"
                                transform="rotate(180 2.66536 117.333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="102.667"
                                r="1.66667"
                                transform="rotate(180 2.66536 102.667)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="88"
                                r="1.66667"
                                transform="rotate(180 2.66536 88)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="73.3333"
                                r="1.66667"
                                transform="rotate(180 2.66536 73.3333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="45"
                                r="1.66667"
                                transform="rotate(180 2.66536 45)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="16"
                                r="1.66667"
                                transform="rotate(180 2.66536 16)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="59"
                                r="1.66667"
                                transform="rotate(180 2.66536 59)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="30.6666"
                                r="1.66667"
                                transform="rotate(180 2.66536 30.6666)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="1.66665"
                                r="1.66667"
                                transform="rotate(180 2.66536 1.66665)"
                                fill="#13C296"
                                />
                            </svg>
                        </span>
                        <span className="absolute -left-7 -bottom-7 z-[-1]">
                            <svg
                                width="107"
                                height="134"
                                viewBox="0 0 107 134"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <circle
                                cx="104.999"
                                cy="132"
                                r="1.66667"
                                transform="rotate(180 104.999 132)"
                                fill="#13C296"
                                />
                                <circle
                                cx="104.999"
                                cy="117.333"
                                r="1.66667"
                                transform="rotate(180 104.999 117.333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="104.999"
                                cy="102.667"
                                r="1.66667"
                                transform="rotate(180 104.999 102.667)"
                                fill="#13C296"
                                />
                                <circle
                                cx="104.999"
                                cy="88"
                                r="1.66667"
                                transform="rotate(180 104.999 88)"
                                fill="#13C296"
                                />
                                <circle
                                cx="104.999"
                                cy="73.3333"
                                r="1.66667"
                                transform="rotate(180 104.999 73.3333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="104.999"
                                cy="45"
                                r="1.66667"
                                transform="rotate(180 104.999 45)"
                                fill="#13C296"
                                />
                                <circle
                                cx="104.999"
                                cy="16"
                                r="1.66667"
                                transform="rotate(180 104.999 16)"
                                fill="#13C296"
                                />
                                <circle
                                cx="104.999"
                                cy="59"
                                r="1.66667"
                                transform="rotate(180 104.999 59)"
                                fill="#13C296"
                                />
                                <circle
                                cx="104.999"
                                cy="30.6666"
                                r="1.66667"
                                transform="rotate(180 104.999 30.6666)"
                                fill="#13C296"
                                />
                                <circle
                                cx="104.999"
                                cy="1.66665"
                                r="1.66667"
                                transform="rotate(180 104.999 1.66665)"
                                fill="#13C296"
                                />
                                <circle
                                cx="90.3333"
                                cy="132"
                                r="1.66667"
                                transform="rotate(180 90.3333 132)"
                                fill="#13C296"
                                />
                                <circle
                                cx="90.3333"
                                cy="117.333"
                                r="1.66667"
                                transform="rotate(180 90.3333 117.333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="90.3333"
                                cy="102.667"
                                r="1.66667"
                                transform="rotate(180 90.3333 102.667)"
                                fill="#13C296"
                                />
                                <circle
                                cx="90.3333"
                                cy="88"
                                r="1.66667"
                                transform="rotate(180 90.3333 88)"
                                fill="#13C296"
                                />
                                <circle
                                cx="90.3333"
                                cy="73.3333"
                                r="1.66667"
                                transform="rotate(180 90.3333 73.3333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="90.3333"
                                cy="45"
                                r="1.66667"
                                transform="rotate(180 90.3333 45)"
                                fill="#13C296"
                                />
                                <circle
                                cx="90.3333"
                                cy="16"
                                r="1.66667"
                                transform="rotate(180 90.3333 16)"
                                fill="#13C296"
                                />
                                <circle
                                cx="90.3333"
                                cy="59"
                                r="1.66667"
                                transform="rotate(180 90.3333 59)"
                                fill="#13C296"
                                />
                                <circle
                                cx="90.3333"
                                cy="30.6666"
                                r="1.66667"
                                transform="rotate(180 90.3333 30.6666)"
                                fill="#13C296"
                                />
                                <circle
                                cx="90.3333"
                                cy="1.66665"
                                r="1.66667"
                                transform="rotate(180 90.3333 1.66665)"
                                fill="#13C296"
                                />
                                <circle
                                cx="75.6654"
                                cy="132"
                                r="1.66667"
                                transform="rotate(180 75.6654 132)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="132"
                                r="1.66667"
                                transform="rotate(180 31.9993 132)"
                                fill="#13C296"
                                />
                                <circle
                                cx="75.6654"
                                cy="117.333"
                                r="1.66667"
                                transform="rotate(180 75.6654 117.333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="117.333"
                                r="1.66667"
                                transform="rotate(180 31.9993 117.333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="75.6654"
                                cy="102.667"
                                r="1.66667"
                                transform="rotate(180 75.6654 102.667)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="102.667"
                                r="1.66667"
                                transform="rotate(180 31.9993 102.667)"
                                fill="#13C296"
                                />
                                <circle
                                cx="75.6654"
                                cy="88"
                                r="1.66667"
                                transform="rotate(180 75.6654 88)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="88"
                                r="1.66667"
                                transform="rotate(180 31.9993 88)"
                                fill="#13C296"
                                />
                                <circle
                                cx="75.6654"
                                cy="73.3333"
                                r="1.66667"
                                transform="rotate(180 75.6654 73.3333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="73.3333"
                                r="1.66667"
                                transform="rotate(180 31.9993 73.3333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="75.6654"
                                cy="45"
                                r="1.66667"
                                transform="rotate(180 75.6654 45)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="45"
                                r="1.66667"
                                transform="rotate(180 31.9993 45)"
                                fill="#13C296"
                                />
                                <circle
                                cx="75.6654"
                                cy="16"
                                r="1.66667"
                                transform="rotate(180 75.6654 16)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="16"
                                r="1.66667"
                                transform="rotate(180 31.9993 16)"
                                fill="#13C296"
                                />
                                <circle
                                cx="75.6654"
                                cy="59"
                                r="1.66667"
                                transform="rotate(180 75.6654 59)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="59"
                                r="1.66667"
                                transform="rotate(180 31.9993 59)"
                                fill="#13C296"
                                />
                                <circle
                                cx="75.6654"
                                cy="30.6666"
                                r="1.66667"
                                transform="rotate(180 75.6654 30.6666)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="30.6666"
                                r="1.66667"
                                transform="rotate(180 31.9993 30.6666)"
                                fill="#13C296"
                                />
                                <circle
                                cx="75.6654"
                                cy="1.66665"
                                r="1.66667"
                                transform="rotate(180 75.6654 1.66665)"
                                fill="#13C296"
                                />
                                <circle
                                cx="31.9993"
                                cy="1.66665"
                                r="1.66667"
                                transform="rotate(180 31.9993 1.66665)"
                                fill="#13C296"
                                />
                                <circle
                                cx="60.9993"
                                cy="132"
                                r="1.66667"
                                transform="rotate(180 60.9993 132)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="132"
                                r="1.66667"
                                transform="rotate(180 17.3333 132)"
                                fill="#13C296"
                                />
                                <circle
                                cx="60.9993"
                                cy="117.333"
                                r="1.66667"
                                transform="rotate(180 60.9993 117.333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="117.333"
                                r="1.66667"
                                transform="rotate(180 17.3333 117.333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="60.9993"
                                cy="102.667"
                                r="1.66667"
                                transform="rotate(180 60.9993 102.667)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="102.667"
                                r="1.66667"
                                transform="rotate(180 17.3333 102.667)"
                                fill="#13C296"
                                />
                                <circle
                                cx="60.9993"
                                cy="88"
                                r="1.66667"
                                transform="rotate(180 60.9993 88)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="88"
                                r="1.66667"
                                transform="rotate(180 17.3333 88)"
                                fill="#13C296"
                                />
                                <circle
                                cx="60.9993"
                                cy="73.3333"
                                r="1.66667"
                                transform="rotate(180 60.9993 73.3333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="73.3333"
                                r="1.66667"
                                transform="rotate(180 17.3333 73.3333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="60.9993"
                                cy="45"
                                r="1.66667"
                                transform="rotate(180 60.9993 45)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="45"
                                r="1.66667"
                                transform="rotate(180 17.3333 45)"
                                fill="#13C296"
                                />
                                <circle
                                cx="60.9993"
                                cy="16"
                                r="1.66667"
                                transform="rotate(180 60.9993 16)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="16"
                                r="1.66667"
                                transform="rotate(180 17.3333 16)"
                                fill="#13C296"
                                />
                                <circle
                                cx="60.9993"
                                cy="59"
                                r="1.66667"
                                transform="rotate(180 60.9993 59)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="59"
                                r="1.66667"
                                transform="rotate(180 17.3333 59)"
                                fill="#13C296"
                                />
                                <circle
                                cx="60.9993"
                                cy="30.6666"
                                r="1.66667"
                                transform="rotate(180 60.9993 30.6666)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="30.6666"
                                r="1.66667"
                                transform="rotate(180 17.3333 30.6666)"
                                fill="#13C296"
                                />
                                <circle
                                cx="60.9993"
                                cy="1.66665"
                                r="1.66667"
                                transform="rotate(180 60.9993 1.66665)"
                                fill="#13C296"
                                />
                                <circle
                                cx="17.3333"
                                cy="1.66665"
                                r="1.66667"
                                transform="rotate(180 17.3333 1.66665)"
                                fill="#13C296"
                                />
                                <circle
                                cx="46.3333"
                                cy="132"
                                r="1.66667"
                                transform="rotate(180 46.3333 132)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="132"
                                r="1.66667"
                                transform="rotate(180 2.66536 132)"
                                fill="#13C296"
                                />
                                <circle
                                cx="46.3333"
                                cy="117.333"
                                r="1.66667"
                                transform="rotate(180 46.3333 117.333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="117.333"
                                r="1.66667"
                                transform="rotate(180 2.66536 117.333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="46.3333"
                                cy="102.667"
                                r="1.66667"
                                transform="rotate(180 46.3333 102.667)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="102.667"
                                r="1.66667"
                                transform="rotate(180 2.66536 102.667)"
                                fill="#13C296"
                                />
                                <circle
                                cx="46.3333"
                                cy="88"
                                r="1.66667"
                                transform="rotate(180 46.3333 88)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="88"
                                r="1.66667"
                                transform="rotate(180 2.66536 88)"
                                fill="#13C296"
                                />
                                <circle
                                cx="46.3333"
                                cy="73.3333"
                                r="1.66667"
                                transform="rotate(180 46.3333 73.3333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="73.3333"
                                r="1.66667"
                                transform="rotate(180 2.66536 73.3333)"
                                fill="#13C296"
                                />
                                <circle
                                cx="46.3333"
                                cy="45"
                                r="1.66667"
                                transform="rotate(180 46.3333 45)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="45"
                                r="1.66667"
                                transform="rotate(180 2.66536 45)"
                                fill="#13C296"
                                />
                                <circle
                                cx="46.3333"
                                cy="16"
                                r="1.66667"
                                transform="rotate(180 46.3333 16)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="16"
                                r="1.66667"
                                transform="rotate(180 2.66536 16)"
                                fill="#13C296"
                                />
                                <circle
                                cx="46.3333"
                                cy="59"
                                r="1.66667"
                                transform="rotate(180 46.3333 59)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="59"
                                r="1.66667"
                                transform="rotate(180 2.66536 59)"
                                fill="#13C296"
                                />
                                <circle
                                cx="46.3333"
                                cy="30.6666"
                                r="1.66667"
                                transform="rotate(180 46.3333 30.6666)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="30.6666"
                                r="1.66667"
                                transform="rotate(180 2.66536 30.6666)"
                                fill="#13C296"
                                />
                                <circle
                                cx="46.3333"
                                cy="1.66665"
                                r="1.66667"
                                transform="rotate(180 46.3333 1.66665)"
                                fill="#13C296"
                                />
                                <circle
                                cx="2.66536"
                                cy="1.66665"
                                r="1.66667"
                                transform="rotate(180 2.66536 1.66665)"
                                fill="#13C296"
                                />
                            </svg>
                        </span>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
    </div>
    <ToastContainer />
    </HelmetProvider>
    );

}


export default Contact;