import React, { useState, useEffect } from 'react';
import { Box,  Paper, Grid, Divider } from '@mui/material';
import InputForm from '../components/InputForm';
import Cookies from 'universal-cookie';
import CarouselSlide from '../components/Carousel/CarouselSlide';
import TableHistory from '../components/Table/TableHistory'

import Footer from '../components/HeaderFooter/Footer';

function Home() {
    let [list_of_urls, set_list_of_urls] = useState([]);
    const [rows, setRows] = useState([]);
    const handle_list_of_urls = (props) => {
      list_of_urls = [...list_of_urls, props]
      set_list_of_urls(list_of_urls);
    }
     const checkCookieExists = (name) => {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
          begin = dc.indexOf(prefix);
          if (begin != 0) return null;
      }
      else
      {
          begin += 2;
          var end = document.cookie.indexOf(";", begin);
          if (end == -1) {
          end = dc.length;
          }
      }
      return decodeURI(dc.substring(begin + prefix.length, end));
    } 
    let check_cookie_exists_or_not = checkCookieExists('cookie_shorted_data');
    let cookies_data = undefined;
    if (check_cookie_exists_or_not == null) {
      cookies_data = [];
    }
    else
    {
      const cookies = new Cookies();
      cookies_data = cookies.get('cookie_shorted_data');
    }

    useEffect(() => {
      let cookies_data = undefined;
      let check_cookie_exists_or_not = checkCookieExists('cookie_shorted_data');
      if (check_cookie_exists_or_not == null) {
        cookies_data = [];
      }
      else
      {
        const cookies = new Cookies();
        cookies_data = cookies.get('cookie_shorted_data');
        setRows(cookies_data);
      }
    })

    
    return (
        <Paper sx={{mx:2, my:4}}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <InputForm onHandleListOfUrls={handle_list_of_urls} />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <CarouselSlide />
            </Grid>
          </Grid>
          {list_of_urls.length != 0 && (
            <Paper sx={{my:2}}>
              <Grid container spacing={2} sx={{mt:2}}>
                <Grid item xs={12} md={12} lg={12}>
                  <TableHistory tableData={list_of_urls} />
                </Grid>
              </Grid>
            </Paper>
          )}

          {rows.length != 0 && (
            <Paper sx={{my:2, display: { xs: 'block', sm: 'none' }}}>
              <Grid container spacing={2} sx={{mt:2}}>
                <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', alignItems: 'center', justifyContent:"center"}}>
                  <Box sx={{ fontSize: 'h6.fontSize' }}>Your recent SnipURLs</Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TableHistory tableData={rows} />
                </Grid>
              </Grid>
            </Paper>
          )}
          <Footer />
        </Paper>

     
    );
}


export default Home;