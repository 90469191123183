import {useState} from 'react'
import Cookies from 'universal-cookie';
import { Divider, Typography, Toolbar, Button, Box, TextField,  Paper } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import https from '../config/https';
import Spinner from '../components/Spinner/Spinner';


function InputForm(props) {

  const checkCookieExists = (name) => {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else
    {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
        end = dc.length;
        }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
} 

  const [input_long_url, set_input_long_url] = useState('');
  const [alias_value, set_alias_value] = useState('');
  const [alias_error_message, set_alias_error_message] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handle_input = (event) => {
    set_input_long_url(event.target.value);
  }

  const handle_alias = (event) => {
    set_alias_value(event.target.value);
  }

  const isUrlValid = (userInput) => {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
        return false;
    else
        return true;
}

  const handle_input_form = (event) =>{
    event.preventDefault();
    set_alias_error_message('');
    let isUrlValid_response = isUrlValid(input_long_url);
    if (isUrlValid_response === false) {
      alert("Please enter a valid url !");
      return null;
    }

    if(alias_value.length > 30){
      set_alias_error_message("The Alias must not be greater than 30 characters.");
      return;
    }
    
    if(alias_value && alias_value.length <= 30){
      available_alias();
      return;
    } else {
      set_input_long_url("");
      make_shorten_api_call();
    }
  }

  const available_alias = async () => {
    let data = {
      alias: alias_value
    };
    setIsLoading(true);
    try {
      const response = await https.post('/alias-check', data); 
      if(!response.data.alias_exist){
        make_shorten_api_call()
      }
      else{
        set_alias_error_message("Alias is not available.");
      }
      setIsLoading(false);
    } catch (error) {}
    setIsLoading(false);
  }

  const showToastMessageError = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const make_shorten_api_call = async () => {
       let data = {
        url: input_long_url,
        alias: alias_value,
        qr_code: true
      };
      let token = localStorage.getItem('token');
      let url = '';
      if(token){
        url='/dash/links'
      }else{
        url='/short-url'
      }
      setIsLoading(true);
      try {
        const response = await https.post(url, data); 
        if(response.data){
          const response_data = {
            original_url : input_long_url,
            short_url : response.data.shorten_url,
            qr_code : response.data.qr_code_path
          }
          if(!token){
            props.onHandleListOfUrls(response_data)
            let check_cookie_exists_or_not = checkCookieExists('cookie_shorted_data');
            const cookies = new Cookies();
            if (check_cookie_exists_or_not == null) {
              let cookie_shorted_data = [response_data];
              cookies.set('cookie_shorted_data', cookie_shorted_data, { path: '/' , 
              maxAge: 604800});
            }
            else {
              let cookie_shorted_data = cookies.get('cookie_shorted_data');
              cookie_shorted_data = [...cookie_shorted_data, response_data];
              cookies.set('cookie_shorted_data', cookie_shorted_data, { path: '/' });
            }
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showToastMessageError(error?.response?.data?.detail)
      }
      setIsLoading(false);
  }

  return (
    <Paper sx={{mx:{ xs: 0, md: 3, lg: 4 }, px:1}}>
      {isLoading == true ? <Spinner /> : <div></div>}
      <Box sx={{px:3, py:4}}>
        <Toolbar disableGutters>
          <Divider sx={{ flexGrow: 1, height: 2, background: "#ddd" }} />
          <Typography variant="h6" component="div" sx={{ p: 1 }}>
            Short Really Long URL
          </Typography>
          <Divider sx={{ flexGrow: 1, height: 2, background: "#ddd" }} />
        </Toolbar>
        <form onSubmit={handle_input_form}>
          <Box sx={{mt:2}}>
            <Box className="w-full space-y-6">
              <Box>
                  <TextField
                    type="url"
                    required
                    value={input_long_url}
                    onChange={handle_input}
                    id="search-form-price"
                    fullWidth
                    label="Your long URL"
                    variant="standard"
                    margin="normal"
                  />
                  <TextField
                    value={alias_value}
                    onChange={handle_alias}
                    id="alias-field"
                    fullWidth
                    label="Enter alias"
                    variant="standard"
                    margin="normal"
                  />
              </Box>
            </Box>
            <Box>
              <Typography sx={{ mt: 1 }} color="error" style={{ fontSize: 14 }}>
                {alias_error_message}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ py: 4 }}
            >
              <Button
                type="submit"
                variant="contained"
                size="medium"
                sx={{ backgroundColor: "#4F46E5", padding: "10px 36px" }}
              >
                Shorten URL
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
      <ToastContainer />
    </Paper>
  );
}

export default InputForm;
