import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css'
import RedirectUrl from './components/RedirectUrl/RedirectUrl';
import AppLayout from './AppLayout'
import AdsInterceptor from './components/AdsInterceptor';


function App() {
  return (
    <div>
      
      <Routes>
        <Route path="/" element={<Navigate to="/app" />} />
          <Route path="/app/*" element={<AppLayout />}>
        </Route>
        <Route path=":param" element={<RedirectUrl />} />
        <Route path="/short/:short_id" element={<AdsInterceptor />} />
        <Route path='*' element={<Navigate to="/app/404" replace />} />
      </Routes>
    </div>
   
  );
}

export default App;
