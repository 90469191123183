import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import {TextField, Link} from '@mui/material';
import https from '../../config/https';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../Spinner/Spinner';
import { useData } from '../../DataContext';
import QRCodeStyling from "qr-code-styling";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function EditLinks({ data }) {
  const { setGlobalData } = useData();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [editedUrl, setEditedUrl] = useState('');
  const [editedIndex, setEditedIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleEditClick = (index, originalUrl) => {
    setEditMode(true);
    setEditedUrl(originalUrl);
    setEditedIndex(index);
  };

  const handleSaveClick = async(index, shortlUrl) => {
    const id= shortlUrl.split("/");
    const urlId = id[id.length-1];
    let data = {original_url: editedUrl};
    setIsLoading(true);
    try {
      const response = await https.patch(`/dash/links/${urlId}`, data); 
      if(response.status === 200){
        showToastMessageSuccess(response?.data?.detail);
        window.location.reload();
      }
      setIsLoading(false);
    } catch (error) {
      showToastMessageError("Something Went Wrong! Please Try After Sometime")
    }
    setIsLoading(false);
    setEditMode(false);
    setEditedUrl('');
    setEditedIndex(null);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setEditedUrl('');
    setEditedIndex(null);
  };

  const showToastMessageError = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastMessageSuccess = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handleDeleteClick = async(index, shortlUrl)=>{
    const id= shortlUrl.split("/");
    const urlId = id[id.length-1];
    setIsLoading(true);
    try {
      const response = await https.delete(`/dash/links/${urlId}`); 
      if(response.status === 204){
        showToastMessageSuccess("Link deleted SuccessFully");
        setTimeout(()=>{
          window.location.reload();
        }, 2000)
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showToastMessageError("Something Went Wrong! Please Try After Sometime")
    }
    setIsLoading(false);
  }

  const handleInputChange = (e) => {
    setEditedUrl(e.target.value);
  };

  const copy_short_url = (url) => {
    try {
      copyToClipboard(url);
    } catch(error) {
        console.error("alert error: ", error);
    }
  }

  async function copyToClipboard(textToCopy) {
    try {
      await navigator.clipboard.writeText(textToCopy);
      showToastMessageSuccess('Text successfully copied');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
  }

  const redirectToQR= (item) => {
    let data = [];
    data.push(item);
    const dataToSend = data;
    setGlobalData(dataToSend);
    navigate('/app/qr-code');
  };

  const handleDownloadQR = (data) => {
    const QrData = JSON.parse(data);
    let qrCode = new QRCodeStyling({
      width: 150,
      height: 150,
      type: "svg",
      data: "",
      image: "",
      dotsOptions: {
        type: "square"
      },
      backgroundOptions: {
          color: "#e9ebee",
      },
      imageOptions: {
          crossOrigin: "anonymous",
          margin: 3
      }
    })
    qrCode.update(QrData)
    qrCode.download({
      name: `QrCode_${new Date()}`,
    })
  }


  return (
    <TableContainer component={Paper} sx={{ overflowY: "scroll", maxHeight: "100vh", mt:1 }}>
      {isLoading === true ? <Spinner />: <div></div>}
      {data.length === 0 ? (
        <Typography variant="h6" align="center" sx={{ fontWeight: 'bold', marginTop: 4 }}>
        No data found
      </Typography>
      ) : (
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Original URL</StyledTableCell>
            <StyledTableCell>Short URL</StyledTableCell>
            {/* <StyledTableCell>Actions</StyledTableCell> */}
            <StyledTableCell>QR Code</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {data.map((item, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
              {editMode && editedIndex === index ? (
                <TextField type="text" value={editedUrl} onChange={handleInputChange} sx={{ width: 300 }} />
              ) : (
                <span>{item.originalUrl}</span>
              )}
              </StyledTableCell>
              <StyledTableCell>
               <Link target='_blank' href={item.shortUrl} underline="none">{item.shortUrl}</Link>
                <br />
                <Button onClick={() => copy_short_url(item.shortUrl)} variant="outlined" color="secondary" size="small" >
                    <ContentCopyIcon />
                </Button>
            
                {editMode && editedIndex === index ? (
                    <>
                    <Button variant="contained" color="success" size="small" onClick={() => handleSaveClick(index, item.shortUrl)} sx={{m:1}}>Save</Button>
                    <Button variant="outlined" color="error" size="small" onClick={handleCancelClick} sx={{m:1}}>Cancel</Button>
                    </>
                ) : (
                    <>
                    <Button variant="outlined" size="small" onClick={() => handleEditClick(index, item.originalUrl)} sx={{m:1}}><BorderColorIcon /></Button>
                    <Button variant="outlined" color="error" size="small" onClick={() =>handleDeleteClick(index, item.shortUrl)} sx={{ m: 1 }}><DeleteIcon /></Button>
                    </>
                )}
              </StyledTableCell>
              {/* <StyledTableCell>
                {editMode && editedIndex === index ? (
                    <>
                    <Button variant="contained" color="success" size="small" onClick={() => handleSaveClick(index, item.shortUrl)} sx={{m:1}}>Save</Button>
                    <Button variant="outlined" color="error" size="small" onClick={handleCancelClick} sx={{m:1}}>Cancel</Button>
                    </>
                ) : (
                    <>
                    <Button variant="outlined" size="small" onClick={() => handleEditClick(index, item.originalUrl)} sx={{m:1}}><BorderColorIcon /></Button>
                    <Button variant="outlined" color="error" size="small" onClick={() =>handleDeleteClick(index, item.shortUrl)} sx={{ m: 1 }}><DeleteIcon /></Button>
                    </>
                )}
                </StyledTableCell> */}
                <StyledTableCell>
                  <Button variant="outlined" size="small" onClick={() => redirectToQR(item)} sx={{m:1}}><QrCode2Icon/></Button>
                  { item.qr_code_data && (
                    <Button variant="outlined" color="success" size="small" onClick={() =>handleDownloadQR(item.qr_code_data)} sx={{ m: 1 }}><CloudDownloadIcon/></Button>
                  )}
                </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

      )}
      <ToastContainer />
    </TableContainer>
  );
}
