import axios from 'axios'
const https = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, 
});
let token = localStorage.getItem('token');

https.interceptors.request.use(
  (config) => {
    if (token) {
      if (config.headers) config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['Content-Type'] = 'application/json; charset=UTF-8';
    return config;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  }
);

// Response interceptor
https.interceptors.response.use(
  (response) => {
    // Modify the response data here (e.g., parse, transform)

    return response;
  },
  (error) => {
    // Handle response errors here

    return Promise.reject(error);
  }
);

export default https;