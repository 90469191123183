import React, {useEffect, useState} from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import DashBoardCardQuota from '../../components/Card/DashBoardCardQuota'
import DashBoardCardAnalytics from '../../components/Card/DashBoardCardAnalytics'
import { Typography } from '@mui/material';
import https from '../../config/https';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../../components/Spinner/Spinner';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function DashBoard() {
  const [isLoading, setIsLoading] = useState(false);
  const [quota, setQuota] = useState({});
  const [analytics, setAnalytics] = useState({});
  const [linksData, setLinksData] = useState({});
    const data = [{header : "Links", total_links : 5, remaining_quota : 10, total_quota : 15 }, {header : "Links", total_links : 5, remaining_quota : 10, total_quota : 15 }, {header : "Links", total_links : 5, remaining_quota : 10, total_quota : 15 }]

    useEffect(()=>{
      getDashBoard()
    },[])

    const getDashBoard = async() => {
      setIsLoading(true);
      try {
        const response = await https.get(`/dash/home`); 
        if(response?.data){
          setQuota(response?.data?.links?.quota);
          setAnalytics(response?.data?.links?.analytics);
          setLinksData(response?.data?.links);
          console.log(analytics)
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showToastMessageError("Something Went Wrong! Please Try After Sometime")
      }
    }

    const showToastMessageError = (msg) => {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    };
  
    const showToastMessageSuccess = (msg) => {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    };

  return (
    <>
      <Box sx={{ flexGrow: 1, px:5 }}>
      {isLoading === true ? <Spinner />: <div></div>}
        <Typography variant="h5" sx={{pb:2}}>
          Links
        </Typography>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={4} md={4} key={1}>
                <DashBoardCardQuota total_links={linksData.total_links} remaining_quota={quota.remaining_quota} total_quota={quota.total_quota} />
            </Grid>
            <Grid item xs={4} sm={4} md={4} key={1}>
                <DashBoardCardAnalytics total_links={linksData.total_links} active_links={analytics.active_link?.active_links} inactive_links={analytics?.inactive_link?.inactive_links} />
            </Grid>
        </Grid>
        <ToastContainer />
      </Box>
    </>
  );
}