import { Link } from 'react-router-dom';
import Footer from '../components/HeaderFooter/Footer';

function PrivacyPolicy () {
    return (
        <div>
               <div>
                    <section className="text-gray-700 bg-gradient-to-r from-emerald-400 to-cyan-400">
                        <div className="container px-5 py-24 mx-auto self-start ">
                        <div className="text-center mb-20 ">
                            <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-6 decoration-solid cursor-text">
                            SnipUrl Privacy Policy
                            </h1>
                            <p className="text-base text-justify leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                            Effective Date: OCT-2023
                            </p>
                            <p className="text-base  text-justify leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto mt-2">
                            Welcome to SnipUrl!
                            </p>
                            <p className="text-base text-justify leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto mt-2">
                             We are committed to protecting your privacy and ensuring the security of your personal information.
                            </p>
                            <p className="text-base text-justify leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto mt-2">
                             This Privacy Policy explains how we collect, use, and safeguard your data when you use our website and services.
                            </p>
                        </div>
                        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                            <div className="w-full lg:w-1/2 px-4 py-2">
                            <details className="mb-4">
                                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                                 Information We Collect
                                </summary>

                                <span>
                                When you use our URL shortening services, we may collect the following types of information:
                                <ul>
                                    <li className="list-item">
                                       <p>
                                       Personal Information:
                                        <li>
                                        Your email address (only if you create an account)
                                        </li>
                                        <li>
                                        Your password (if you create an account)
                                        </li>
                                        </p> 
                                    </li>
                                    <li>
                                    Non-Personal Information:
                                    <li>
                                     IP address
                                    </li>
                                    <li>
                                    Browser type and version
                                    </li>
                                    <li>
                                    Date and time of your visit
                                    </li>
                                    <li>
                                    Referring websites
                                    </li>
                                    </li>
                                </ul>
                                </span>
                            </details>
                            <details className="mb-4">
                                <summary className="font-semibold bg-gray-200 rounded-md py-2 px-4">
                                How We Use Your Information
                                </summary>

                                <span>
                                We use the information we collect for the following purposes:
                                <ol>
                                    <li>
                                    a. Account Management: If you create an account, we use your email address and password to manage your account and provide you with access to your shortened URLs.
                                    </li>
                                    <li>
                                    b. Analytics: We collect non-personal information for website analytics, to help us improve our services and better understand how users interact with our website.
                                    </li>
                                    <li>
                                    c. Security: We use your IP address to help identify and prevent fraudulent activities and unauthorized access to your account.
                                    </li>
                                </ol>
                                </span>
                            </details>
                            <details className="mb-4">
                                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                                Cookies and Tracking Technologies
                                </summary>

                                <span>
                                We may use cookies and similar tracking technologies to improve your experience on our website. You can configure your browser to block or delete cookies, but this may affect your ability to use some features on our website.
                                </span>
                            </details>
                            <details className="mb-4">
                                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                                Your Rights
                                </summary>

                                <span className="px-4 py-2">
                                You have the right to access, update, or delete your personal information. You may also request that we restrict the processing of your data. Please contact us at <Link className='text-blue-600'  to="/contact">here</Link> to exercise these rights.
                                </span>
                            </details>
                            <details className="mb-4">
                                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                                Children's Privacy
                                </summary>

                                <span className="px-4 py-2">
                                Our services are not intended for users under the age of 13. We do not knowingly collect personal information from children.
                                </span>
                            </details>
                            </div>
                            <div className="w-full lg:w-1/2 px-4 py-2">
                            <details className="mb-4">
                                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                                Third-Party Services
                                </summary>

                                <span className="px-4 py-2">
                                We may use third-party services for analytics and to enhance your experience. These services may collect their own information and have their own privacy policies. We recommend that you review the privacy policies of these third parties.
                                </span>
                            </details>
                            <details className="mb-4">
                                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                                Data Security
                                </summary>

                                <span className="px-4 py-2">
                                We take reasonable measures to protect your data against unauthorized access and disclosure. We use industry-standard encryption for data transmission.
                                </span>
                            </details>
                            <details className="mb-4">
                                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                                Data Retention
                                </summary>

                                <span className="px-4 py-2">
                                We retain your information as long as it is necessary to fulfill the purposes outlined in this policy or as required by applicable laws.
                                </span>
                            </details>

                            <details className="mb-4">
                                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                                Changes to this Privacy Policy
                                </summary>

                                <span className="px-4 py-2">
                                We may update this Privacy Policy to reflect changes in our practices. We will notify you of any significant changes, and the updated policy will be posted on our website with the revised effective date.
                                </span>
                            </details>
                            <details className="mb-4">
                                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                                Contact Us
                                </summary>

                                <span className="px-4 py-2">
                                <ol>
                                    <li>
                                    If you have any questions, concerns, or requests regarding your privacy, please contact us <Link className='text-blue-600'  to="/contact">here</Link>.
                                    </li>
                                    <li>
                                    Thank you for using SnipUrl. We are committed to safeguarding your privacy and providing a secure and user-friendly experience.
                                    </li>
                                </ol>
                                </span>
                            </details>
                            </div>
                        </div>
                        </div>
                    </section>
                </div>
                <Footer />
        </div>
    );
}

export default PrivacyPolicy;