import axios from 'axios'
const loginInterceptors = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, 
});

loginInterceptors.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

loginInterceptors.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default loginInterceptors;