import React, {  useRef, useEffect, useState } from 'react';
import { TextField, Slider, Radio, RadioGroup,FormControl,FormLabel, FormControlLabel, InputAdornment, Button, Container, Typography, Grid, Paper, Box, MenuItem, Popover, InputLabel, Select } from '@mui/material';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GetAppIcon from '@mui/icons-material/GetApp';
import QRCodeStyling from "qr-code-styling";
import Spinner from '../Spinner/Spinner';
import https from '../../config/https';
import { ToastContainer, toast } from 'react-toastify';
import InputColor from 'react-input-color';
import makeStyles from "@material-ui/core/styles/makeStyles";


const ColorPickerTextField = styled(TextField)({
    '& .MuiInputBase-input': {
      padding: '10px',
    },
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: "20px",
      flexGrow: 1,
      padding: "10px",
      display: "flex"
    }
  }));

  let qrCode = new QRCodeStyling({
    width: 150,
    height: 150,
    type: "png",
    data: "",
    image: "",
    dotsOptions: {
      type: "square"
    },
    backgroundOptions: {
        color: "#e9ebee",
    },
    imageOptions: {
        crossOrigin: "anonymous",
        margin: 3
    }
});
const QrCodeComponent = ({url}) => {
  const ref = useRef(null);
  const classes = useStyles();
  const [fileDataURL, setFileDataURL] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dotMode, setDotMode] = useState('square');
  const [linkUrl, setLinkUrl] = useState(url[0]?.shortUrl);
  const [size, setSize] = useState(50);
  const [dotColor1, setDotColor1] = useState('#000000');
  const [dotColor2, setDotColor2] = useState('#000000');
  const [bgColor, setBgColor] = useState('#fff');
  const [cornerSquare, setCornerSquare] = useState('square');
  const [cornerDots, setCornerDots] = useState('square');
  const [imageType, SetImageType] = useState('png')

  const handleInputChangeLinkUrl = (value) =>{
    setLinkUrl(value)
  }
  useEffect(()=>{
    qrCode.update({
      data: linkUrl
    });
  }, [linkUrl])

  const handleInputChangeSize = (value) =>{
    setSize(value)
  }
  useEffect(()=>{
    qrCode.update({
      width: size*3,
      height: size*3,
    });
  }, [size])

  useEffect(()=>{
    qrCode.update({
      dotsOptions: {
        gradient: {
        type: "linear",
        rotation: 0 ,
          colorStops : [
            {offset: 0, color: dotColor1 },
            {offset: 1, color: dotColor2 }
          ],
        }
      }
    });
  }, [dotColor1, dotColor2])

  useEffect(()=>{
    qrCode.update({
      backgroundOptions: {
        color: bgColor,
      }
    })
  }, [bgColor])

  const handleInputChangeDot = (value) => {
    setDotMode(value)
  }
  useEffect(()=>{
    qrCode.update({
      dotsOptions:{type: dotMode}
    });
  }, [dotMode])

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if(file.size > 20480){
      showToastMessageError("Logo size is too large! Maximum logo size allowed is 20KB");
    } else {
      handleFileReaderChange(file);
    }
  };
  const handleFileReaderChange = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      let dataURL = reader.result;
      if (dataURL) {
        setFileDataURL(prevState => dataURL);
      }
    };
    reader.readAsDataURL(file);
  };
  useEffect(() => {
    qrCode.update({
      image: fileDataURL
    });
  }, [fileDataURL]);

  const handleInputChangeCorner = (value) =>{
    setCornerSquare(value)
  }
  useEffect(() => {
    qrCode.update({
      cornersSquareOptions:{type: cornerSquare}
    });
  }, [cornerSquare]);

  const handleInputChangeCornerDots = (value) =>{
    setCornerDots(value)
  }
  useEffect(() => {
    qrCode.update({
      cornersDotOptions:{type: cornerDots}
    });
  }, [cornerDots]);

  const handleInputChangeImageType = (value) =>{
    SetImageType(value)
  }
  useEffect(() => {
    qrCode.update({
      type: imageType
    });
  }, [imageType]);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  const handleClearLogo = () =>{
    setFileDataURL('');
  }

  const showToastMessageError = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleDownloadQR = async () => {
    const short = linkUrl.split('/');
    const short_id = short[short.length-1];
    const qr_code_url = JSON.stringify(qrCode._options);
    let data = {
      short_id,
      qr_code_url
    }
    try {
      setIsLoading(true);
      const response = await https.post('/dash/qr', data); 
      if(response.data){
        qrCode.download({
          name: `QrCode_${new Date()}`,
          extension: imageType
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showToastMessageError(error?.response?.data?.detail)
    }
  }


  return (
    <Container>
        {isLoading === true ? <Spinner />: <div></div>}
        {url.length === 0 ? (
          <Typography variant="h6" align="center" sx={{ fontWeight: 'bold', marginTop: 4 }}>
            No data found! First Create a Couple of links
          </Typography>
        ) : (
       <Grid container spacing={3}>
      <Grid item xs={12} lg={6}>
      <Paper style={{ padding: 20, marginTop: 20 }} lg={6} className={classes.root}>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <InputLabel id="dropdown-label">Select Your URL</InputLabel>
              <Select
                labelId="dropdown-label"
                id="dropdown"
                value={linkUrl}
                onChange={(e) => handleInputChangeLinkUrl( e.target.value)}
                label="Select an option"
              >
                {url.map((option) => (
                  <MenuItem key={option.shortUrl} value={option.shortUrl}>
                    {option.shortUrl}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Typography id="slider" gutterBottom>
                Size
              </Typography>
              <Slider
                value={size}
                onChange={(_, value) => handleInputChangeSize( value)}
                aria-labelledby="slider"
                step={5}
                marks
                min={25}
                max={150}
              />
            </Grid>

            <Grid item xs={12}>
            <Grid container>
              <Grid item xs={8} md={8} lg={8}>
                <input
                  type="file"
                  accept="image/*"
                  id="logo"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <label htmlFor="logo">
                  <Button
                    variant="outlined"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Logo
                  </Button>
                </label>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={handleClearLogo}
                >
                  Clear Logo
                </Button>
              </Grid>
            </Grid>
            </Grid>

            <Grid container sx={{mx:3, mt:4}}>
              <Grid item xs={12} md={6} lg={6}>
              <Box>Choose Dot Color 1</Box>
                <InputColor
                  initialValue={dotColor1}
                  onChange={(color) => setDotColor1(color.hex)}
                  placement="right"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Box>Choose Dot Color 2</Box>
                <InputColor
                  initialValue={dotColor2}
                  onChange={(color) => setDotColor2(color.hex)}
                  placement="right"
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box>Choose Background Color</Box>
              <InputColor
                initialValue={bgColor}
                onChange={(color) => setBgColor(color.hex)}
                placement="right"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Dot Mode</FormLabel>
                <RadioGroup
                  row
                  aria-label="dot-mode"
                  name="dotMode"
                  value={dotMode}
                  onChange={(e) => handleInputChangeDot( e.target.value)}
                >
                  <FormControlLabel value="square" control={<Radio />} label="Square" />
                  <FormControlLabel value="dots" control={<Radio />} label="Dots" />
                  <FormControlLabel value="rounded" control={<Radio />} label="Rounded" />
                  <FormControlLabel value="extra-rounded" control={<Radio />} label="extra-rounded" />
                  <FormControlLabel value="classy" control={<Radio />} label="Classy" />
                  <FormControlLabel value="extra-classy" control={<Radio />} label="Extra Classy" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Corner Square Options</FormLabel>
                <RadioGroup
                  row
                  aria-label="dot-mode"
                  name="dotMode"
                  value={cornerSquare}
                  onChange={(e) => handleInputChangeCorner( e.target.value)}
                >
                  <FormControlLabel value="square" control={<Radio />} label="Square" />
                  <FormControlLabel value="dots" control={<Radio />} label="Circle" />
                  <FormControlLabel value="extra-rounded" control={<Radio />} label="extra-rounded" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Corner Dots Options</FormLabel>
                <RadioGroup
                  row
                  aria-label="dot-mode"
                  name="dotMode"
                  value={cornerDots}
                  onChange={(e) => handleInputChangeCornerDots( e.target.value)}
                >
                  <FormControlLabel value="square" control={<Radio />} label="Square" />
                  <FormControlLabel value="dots" control={<Radio />} label="Circle" />
                </RadioGroup>
              </FormControl>
            </Grid>

          </Grid>
        </form>
      </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
      <Paper elevation={3} style={{ padding: 20, marginTop: 20 }} lg={6}>
      <Box display="flex" justifyContent="center">
          <Grid ref={ref} id="my-canvas"></Grid>
      </Box>
      <Box mt={3} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={handleDownloadQR}
            >
              Download
            </Button>
            <Select
              sx={{mx:2}}
              labelId="imageType-label"
              id="imageType"
              value={imageType}
              onChange={(e) => handleInputChangeImageType( e.target.value)}
              label="Select an option"
              >
              <MenuItem key='PNG' value="png">PNG</MenuItem>
              <MenuItem key='jpeg' value="jpeg">JPEG</MenuItem>
              <MenuItem key='svg' value="svg">SVG</MenuItem>
              <MenuItem key='webp' value="webp">WEBP</MenuItem>
              </Select>
          </Box>
          </Paper>
      </Grid>
      </Grid>
      )}
      <ToastContainer />
    </Container>
  );
};

export default QrCodeComponent;