import React from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function DashBoardCardQuota(props) {
  const navigate = useNavigate();
  const { total_links, remaining_quota, total_quota} = props;

  const navigateLinks = () => {
    navigate('/app/dashboard/links');
  }

  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <CardContent sx={{ textAlign: 'center'}}>
          <Typography  variant="h5" component="div" sx={{backgroundColor:'#F87171', color:'#fff'}}>
            Quota
          </Typography>
          <Typography sx={{ fontSize: 18, mt:2 }} color="text.primary" gutterBottom>
            Total Links Created: {total_links}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Reamining Quota: {remaining_quota}
          </Typography>
          <Typography variant="body2">
            Total Quota: {total_quota}
          </Typography>
          <Typography sx={{ textAlign: 'right', px:5 }}>
            <Button size="small" onClick={navigateLinks} >View More</Button>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}