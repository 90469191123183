import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function CommingSoon() {

  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="90vh">
        <Card sx={{p:10, border: '2px solid #0E349F'}}>
            <Typography variant="h3" sx={{color:'#0E349F'}}>
                Comming Soon...
            </Typography>
        </Card>
    </Box>
  );
}

export default CommingSoon;
