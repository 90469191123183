import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import TableHistory from '../Table/TableHistory';
import {Stack} from '@mui/material';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';



export default function HistoryModal() { 
  const [state, setState] = useState({
    right: false,
  });
  const [rows, setRows] = useState([]);

  const toggleDrawer = (anchor, open) => (event) => {
    let cookies_data = undefined;
    setState({ ...state, [anchor]: open });

    let check_cookie_exists_or_not = checkCookieExists('cookie_shorted_data');
    if (check_cookie_exists_or_not == null) {
      cookies_data = [];
    }
    else
    {
      const cookies = new Cookies();
      cookies_data = cookies.get('cookie_shorted_data');
      setRows(cookies_data);
    }
  };

  const checkCookieExists = (name) => {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else
    {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
        end = dc.length;
        }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
  } 

  const list = (anchor) => (
    <Box
      sx={{ width: 'auto' }}
      role="presentation"
    >
        <Stack  direction="row" spacing={60} sx={{ m: 3 }}>
            <Box sx={{ fontSize: 'h6.fontSize' }}>Your recent SnipURLs</Box>
            <CloseIcon onClick={toggleDrawer(anchor, false)} fontSize="large" />
        </Stack >
      <Divider/>
      <TableHistory tableData={rows} />
    </Box>
  );

  return (
    <div>
          <Link 
            style={{}}
            onClick={toggleDrawer('right', true)}>
              History of urls
            </Link>
          <Drawer
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer("right", false)}
          >
            {list('right')}
          </Drawer>
    </div>
  );
}
