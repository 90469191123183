import React from 'react';
import Slider from 'react-slick';
import { Paper, Typography, Container } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const slideImage =[
    {src:'https://ik.imagekit.io/surls/crausal/1.png?updatedAt=1707049881853',name:"First Image"},
    {src:'https://ik.imagekit.io/surls/crausal/2.png?updatedAt=1707049881706',name:"Second Image"},
    {src:'https://ik.imagekit.io/surls/crausal/3.png?updatedAt=1707049881941',name:"Third Image"},
    {src:'https://ik.imagekit.io/surls/crausal/4.png?updatedAt=1707049881857', name:"Fourth Image"},
    {src:'https://ik.imagekit.io/surls/crausal/5.png?updatedAt=1707049881857', name:'Fivth Image'},
    {src:'https://ik.imagekit.io/surls/crausal/6.png?updatedAt=1707049881904', name:'Sixth Image'},
    {src:'https://ik.imagekit.io/surls/crausal/7.png?updatedAt=1707049881910', name:'Seventh Image'}
]
const CarouselSlide = () => {
  
    const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, 
  };

  return (
    <Container maxWidth="md" height='100vh'>
      <Slider {...settings}>
      {slideImage.map((data, index) => (
          <Paper key={index}>
            <img src={data.src} alt={data.name} width='100%'/>
          </Paper>
        ))}
      </Slider>
    </Container>
  );
};

export default CarouselSlide;
