import Footer from '../components/HeaderFooter/Footer';

function About () {
    return (
    <div> 
        <div className="py-16 bg-white">  
        <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
            <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                <div className="md:5/12 lg:w-5/12">
                <img src="https://tailus.io/sources/blocks/left-image/preview/images/startup.png" alt="image" loading="lazy" width="" height=""/>
                </div>
                <div className="md:7/12 lg:w-6/12">
                <h1 className="text-2xl text-gray-900 font-bold md:text-4xl">Welcome to SnipUrl</h1>
                <h2 className="mt-6 text-xl text-gray-600 font-bold md:text-xl">
                We turn long and unwieldy web addresses into concise, shareable links.</h2>
                <h3 className="mt-6 text-xl text-gray-600 font-bold sm:text-xl">We're passionate about simplifying the way you share, promote, and track your online content.</h3>
                <p className="mt-6 mb-6 text-gray-600">
                At SnipUrl, we understand the power of simplicity. We know that every character counts, especially in today's fast-paced digital world. That's why we've dedicated ourselves to providing you with a hassle-free URL shortening experience.
                </p>
                <bold className="mt-6 mb-6 text-gray-800 text-center">Our Mission</bold>
                <p className="mt-6 text-gray-600">
                Our mission is straightforward: to make your online experience smoother. Whether you're sharing links on social media, sending links through messaging apps, or creating QR codes, we're here to ensure that your URLs are concise, professional, and, most importantly, user-friendly.
                </p>
                </div>
            </div>
            <section className="relative pt-16 bg-blueGray-50">
                <div className="container mx-auto">
                <div className="flex flex-wrap items-center">
                    <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-78">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-500">
                        <img alt="..." src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=700&amp;q=80" className="w-full align-middle rounded-t-lg" />
                        <blockquote className="relative p-8 mb-4">
                        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95" className="absolute left-0 w-full block h-95-px -top-94-px">
                            <polygon points="-30,95 583,95 583,65" className="text-pink-500 fill-current"></polygon>
                        </svg>
                        <h4 className="text-xl font-bold text-blueGray-500">
                            Why Choose Us?
                        </h4>
                        <p className="text-md font-light mt-2 text-blueGray-500">
                            Here are the various reasons to choose us ...
                        </p>
                        </blockquote>
                    </div>
                    </div>

                    <div className="w-full md:w-12/12 px-4">
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-6/12 px-4">
                        <div className="relative flex flex-col mt-4">
                            <div className="px-4 py-5 flex-auto">
                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                <i className="fas fa-sitemap"></i>
                            </div>
                            <h6 className="text-xl mb-1 font-semibold">Privacy</h6>
                            <p className="mb-4 text-blueGray-500">
                             We respect your privacy. We do not sell your data, and we are committed to keeping your information secure.                            </p>
                            </div>
                        </div>
                    
                        <div className="relative flex flex-col min-w-0">
                            <div className="px-4 py-5 flex-auto">
                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                <i className="fas fa-drafting-compass"></i>
                            </div>
                            <h6 className="text-xl mb-1 font-semibold">
                              No Sign-up Required
                            </h6>
                            <p className="mb-4 text-blueGray-500">
                             We believe in minimal barriers to access. You can start shortening URLs without the need for an account.                            </p>
                            </div>
                        </div>
                        </div>
                        <div className="w-full md:w-6/12 px-4">
                        <div className="relative flex flex-col mt-4">
                            <div className="px-4 py-5 flex-auto">
                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                <i className="fas fa-sitemap"></i>
                            </div>
                            <h6 className="text-xl mb-1 font-semibold">Reliability</h6>
                            <p className="mb-4 text-blueGray-500">
                             We are committed to providing you with a reliable and secure service. You can trust us to keep your links active and functional at all times.
                            </p>
                            </div>
                        </div>
                    
                        <div className="relative flex flex-col min-w-0">
                            <div className="px-4 py-5 flex-auto">
                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                <i className="fas fa-drafting-compass"></i>
                            </div>
                            <h6 className="text-xl mb-1 font-semibold">
                                    Ease of Use
                            </h6>
                            <p className="mb-4 text-blueGray-500">
                             We've designed our platform with simplicity in mind. Shortening a URL is a breeze, and you can do it in just a few clicks.
                            </p>
                            </div>
                        </div>
                        </div>
                        <div className="w-full md:w-6/12 px-4">
                        <div className="relative flex flex-col min-w-0 mt-4">
                            <div className="px-4 py-5 flex-auto">
                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                <i className="fas fa-newspaper"></i>
                            </div>
                            <h6 className="text-xl mb-1 font-semibold">Customization</h6>
                            <p className="mb-4 text-blueGray-500">
                            Make your links truly your own. We offer customization options, allowing you to choose custom aliases for your URLs.
                            </p>
                            </div>
                        </div>
                        <div className="relative flex flex-col min-w-0">
                            <div className="px-4 py-5 flex-auto">
                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                <i className="fas fa-file-alt"></i>
                            </div>
                            <h6 className="text-xl mb-1 font-semibold">Analytics</h6>
                            <p className="mb-4 text-blueGray-500">
                            Gain insights into how your links are performing. We offer detailed click analytics to help you make data-driven decisions.
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <footer className="relative bg-blueGray-50 pt-8 pb-6 mt-2">
                <div className="container mx-auto px-4">
                    <div className="flex flex-wrap items-center md:justify-between justify-center">
                    <div className="w-full md:w-6/12 px-4 mx-auto text-center">
                        <div className="text-sm text-blueGray-500 font-semibold py-1">
                        Made with love & curiosity !
                        </div>
                    </div>
                    </div>
                </div>
                </footer>
            </section>
        </div>
        </div>
        <Footer />
    </div>
    );

}


export default About;