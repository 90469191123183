import Footer from '../components/HeaderFooter/Footer';

function Products () {
    const linkImage = require('../assets/images/link.png');
    const qrCodeImage = require('../assets/images/qr-code.png');
    const linkInBioImage = require('../assets/images/link-in-bio.png');
    return (
        <div>
            <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-12">
                <div className="text-center pb-12">
                    <h2 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-indigo-600">
                        We have the best tools set
                    </h2>
                    <h3 className="font-bold text-xl md:text-xl lg:text-3xl font-heading mt-5 mb-6 text-gray-900">
                        Check our awesome tools for all your online needs...
                    </h3>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div className="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
                        <div className="mb-8">
                            <img className="object-center object-cover rounded-full h-36 w-36" src={linkImage} alt="short-link" />
                        </div>
                        <div className="text-center">
                            <p className="text-xl text-gray-700 font-bold mb-2">URL Shortner</p>
                            <p className="text-base text-gray-400 font-normal">Shorten Really Long Urls</p>
                        </div>
                    </div>
                    <div className="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
                        <div className="mb-8">
                            <img className="object-center object-cover rounded-full h-36 w-36" src={qrCodeImage} alt="qr-code" />
                        </div>
                        <div className="text-center">
                            <p className="text-xl text-gray-700 font-bold mb-2">QR Code Generator</p>
                            <p className="text-base text-gray-400 font-normal">Automatically create QR codes for shorten urls</p>
                        </div>
                    </div>
                    <div className="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
                        <div className="mb-8">
                            <img className="object-center object-cover rounded-3xl h-36 w-36" src={linkInBioImage} alt="link-in-bio" />
                        </div>
                        <div className="text-center">
                            <p className="text-xl text-gray-700 font-bold mb-2">Linkin Bio Page</p>
                            <p className="text-base text-gray-400 font-normal">Generate impressive and engaging link-in-bio page</p>
                        </div>
                    </div>
                </div>
            </section>            
            <Footer />
        </div>
    );

}


export default Products;