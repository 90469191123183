import React, { useState, useEffect, useRef } from 'react';
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  Grid
} from '@material-ui/core';
import https from '../config/https';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../components/Spinner/Spinner';
import ReCAPTCHA from "react-google-recaptcha";


const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 400,
    margin: 'auto',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    padding: 10,
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

const ForgotPassword = () => {
  const recaptcha = useRef();
  const siteKey = process.env.REACT_APP_SITE_KEY;
  const navigate = useNavigate();
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isResent, setIsResent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [remainingTime, setRemainingTime] = useState(30);
  const [isOtpValid, setIsOtpValid] = useState(false);


  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  useEffect(() => {
    let intervalId;
    if (remainingTime > 0) {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    if (remainingTime === 0) {
        setIsResent(true);
      }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isResent, remainingTime]);


  const showToastMessageError = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastMessageSuccess = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    validateForm(newPassword, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newRetypePassword = e.target.value;
    setConfirmPassword(newRetypePassword);
    validateForm(newPassword, newRetypePassword);
  };

  const validateForm = (newPassword, newRetypePassword) => {
    const isValid = newPassword === newRetypePassword && newPassword.length >= 6;
    setIsFormValid(isValid);
  };


  const handleSubmitEmail = async(e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (validateEmail(email)) {
      if (!captchaValue) {
        showToastMessageError("Please verify the reCAPTCHA!");
        return;
      }
      setIsLoading(true);
      try {
        const response = await https.post(`/auth/reset/password?email=${email}`); 
        if(response.status === 200){
          setStep(2);
          setEmailError('');
        }
        setIsLoading(false);
      } catch (error) {
        showToastMessageError(error?.response?.data?.detail)
      }
      setIsLoading(false);
    } else {
      setEmailError('Invalid email address');
    }
  };

  const handleResendOtp = async() => {
    setIsLoading(true);
    try {
      const response = await https.post(`/auth/otp/resend?email=${email}&otp_type=password_verification`); 
      if(response?.status === 200){
        setIsResent(true);
        setOtp('');
        setIsOtpValid(false);
        setErrorMessage('');
        setIsResent(false);
        setRemainingTime(30);
        showToastMessageSuccess('OTP Resend Successfully.');
      }
      setIsLoading(false);
    } catch (error) {
        showToastMessageError('Please enter a valid 6-digit OTP.');
    }
    setIsLoading(false);
  };


  const handleSubmitOTP = async(e) => {
    e.preventDefault();
    setIsLoading(true);
      try {
        const response = await https.post(`/auth/otp/verify?otp=${otp}&email=${email}&otp_type=password_verification`); 
        if(response.status === 200){
          setStep(3);
          setEmailError('');
        }
        setIsLoading(false);
      } catch (error) {
        showToastMessageError(error?.response?.data?.detail)
      }
      setIsLoading(false);
  };

  const handleSubmitNewPassword = async(e) => {
    e.preventDefault();
    if(isFormValid){
      let data = {email: email, password: newPassword};
      setIsLoading(true);
      try {
        const response = await https.post(`/auth/forgotPassword`, data); 
        if(response.status === 200){
          showToastMessageSuccess('Password successfully updated!');
          setTimeout(() => {
            navigate('/app/login');
          }, "2000");
        }
        setIsLoading(false);
      } catch (error) {
        showToastMessageError(error?.response?.data?.detail)
      }
      setIsLoading(false)
    } else{
      showToastMessageError("Password Do not Matched!");
    }
  };

  return (
    <Container className={classes.container}>
      {isLoading == true ? <Spinner />: <div></div>}
      <Paper elevation={3}>
        <form className={classes.form}>
          {step === 1 && (
            <>
              <Typography variant="h5" gutterBottom>
                Forgot Password
              </Typography>
              <TextField
                label="Email"
                type="email"
                value={email}
                error={!!emailError}
                helperText={emailError}
                onChange={(e) => setEmail(e.target.value)}
                className={classes.textField}
                required
              />
              <Box sx={{pt:1, pb:2}}>
                <ReCAPTCHA ref={recaptcha} sitekey={siteKey} />
              </Box>
              <Button
              sx={{mt:2}}
                type="submit"
                variant="contained"
                className={classes.button}
                onClick={handleSubmitEmail}
              >
                Submit
              </Button>
            </>
          )}

          {step === 2 && (
            <>
              <Typography variant="h5" gutterBottom>
                Enter OTP
              </Typography>
              <TextField
                label="OTP"
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className={classes.textField}
                required
              />
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleResendOtp}
                    disabled={!isResent}
                  >
                    {remainingTime > 0
                      ? `Resend OTP (${remainingTime}s)`
                      : 'Resend OTP'}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleSubmitOTP}
                  >
                    Submit OTP
                  </Button>
                </Grid>
              </Grid>
              <Box sx={{mt:2}} align="center" >
              <Typography>Check your Spam Email too!</Typography>
              </Box>
            </>
          )}

          {step === 3 && (
            <>
              <Typography variant="h5" gutterBottom>
                Set New Password
              </Typography>
              <TextField
                label="New Password"
                type="password"
                value={newPassword}
                onChange={handlePasswordChange}
                className={classes.textField}
                required
              />
              <TextField
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                className={classes.textField}
                required
              />
              <Button
                type="submit"
                variant="contained"
                className={classes.button}
                onClick={handleSubmitNewPassword}
              >
                Update Password
              </Button>
            </>
          )}
        </form>
      </Paper>
      <ToastContainer />
    </Container>
  );
};

export default ForgotPassword;
