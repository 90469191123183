import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Header from '../HeaderFooter/Header';
import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Link from '@material-ui/core/Link';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function SidebarDrawer() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [showDrawer, setShowDrawer] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() =>{
    let token = localStorage.getItem('token');
    if(token){
      setShowDrawer(true)
    }
  }, [])

  const logout = () => {
    localStorage.removeItem("token");
    navigate('/app');
    window.location.reload();
  };

  const sidebarArray = [{text:"Dashboard", link:"/app/dashboard"}, {text:"Links", link:"/app/dashboard/links"}, {text:"QR Codes", link:"/app/qr-code"}, {text:"Link-in-Bio", link:"/app/comming-soon"}, {text:"Custom Links", link:"/app/comming-soon"}];
  const loginSignupArray = [{text:"Support", link:"/app/contact"}, {text:"Logout", link:"/app/login"},]


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{backgroundColor: "#17143F"}}>
        <Toolbar>
        {showDrawer && ( 
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ml: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon /> 
          </IconButton> )}
          <Header />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box component="div" sx={{ mr: 2 }}><img src="https://ik.imagekit.io/surls/snip_logo/apple-touch-icon.png?updatedAt=1697361926884" style={{height: 20}}
              alt="snip-url-logo" /></Box>
          <Box component="div" sx={{ mr: 4 }}>SnipUrl.in</Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {sidebarArray.map((data, index) => (
            <ListItem key={data.text} disablePadding>
              <ListItemButton button component={Link} to={data.link}>
                <ListItemIcon>
                  {index === 0 ? <DisplaySettingsIcon /> : index === 1 ? <LinkIcon /> : index === 2 ? <QrCodeScannerIcon /> : index === 3 ? <AccountBoxIcon /> : < DashboardCustomizeIcon /> }
                </ListItemIcon>
                <ListItemText primary={data.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {loginSignupArray.map((data, index) => (
            <ListItem key={data.text} disablePadding>
              {index == 1 ? (
                <ListItemButton button  onClick={logout}>
                  <ListItemIcon>
                    {index === 0 ? <ContactPageIcon /> : index ===1 ? <LoginIcon /> : <ContactPageIcon />}
                  </ListItemIcon>
                  <ListItemText primary={data.text} />
                </ListItemButton>
              ) : (
                <ListItemButton button  component={Link} to={data.link}>
                  <ListItemIcon>
                    {index === 0 ? <ContactPageIcon /> : index ===1 ? <LoginIcon /> : <ContactPageIcon />}
                  </ListItemIcon>
                  <ListItemText primary={data.text} />
                </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open} sx={{p:{xs:5, sm:2, md:1}}}>
        <DrawerHeader />
      </Main>
    </Box>
  );
}
