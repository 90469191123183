import React from "react";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import {useParams } from 'react-router-dom';



function AdsInterceptor(){
    const { short_id } = useParams();
    // let base_url = "http://localhost:8000/interceptor/"
    let base_url = process.env.REACT_APP_BASE_URL ;
    let final_url = base_url +'/interceptor/'+ short_id;

    // ############# Ads by Monetag ##################
    const script = document.createElement('script');
    script.src = 'https://alwingulla.com/88/tag.min.js';
    script.setAttribute('data-zone', '57736');
    script.setAttribute('data-cfasync', 'false');
    script.async = true;
    document.head.appendChild(script);
    // ############# Ads by Monetag ##################

    const wait_and_redirect = () => {
            window.setTimeout(function(){
            window.location.href = final_url;

    }, 10000);
     // ############# AdsBlocker Detection ############
     let fakeAd = document.createElement("div"); 
     fakeAd.className = 
     "textads banner-ads banner_ads ad-unit ad-zone ad-space adsbox"
         
     fakeAd.style.height = "1px"
         
     document.body.appendChild(fakeAd) 
         
     let x_width = fakeAd.offsetHeight; 
     let msg = document.getElementById("msg") 
         
         
         if(x_width){ 
         console.log("No AdBlocker Detected.") 
         }else{ 
         alert("AdBlocker detected, Our website is made possible by displaying online advertisements to our visitors. Please consider supporting us by disabling your ad blocker.") 
         } 
     // ############# AdsBlocker Detection ############
    }
    return (
        
        <div class="bg-gradient-to-r from-purple-300 to-blue-200">
            <div class="w-12/12 m-auto py-16 min-h-screen flex items-center justify-center">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
            <div class="border-t border-gray-200 text-center pt-8">
            <h1 class="text-7xl font-bold text-purple-300">Powered by SnipUrl</h1>
            <h3 class="text-5xl font-italic text-purple-400 my-5">Redirecting in ...</h3>
            <div className='flex items-center justify-center my-5'>
                <CountdownCircleTimer 
                    isPlaying
                    duration={10}
                    colors={['#6ed408', '#02732c', '#0bdbd4', '#c44329', '#bf7711', '#e0cf10', '#6ed408', '#02732c', '#0bdbd4', '#0b31db', '#7d0ced']}
                    colorsTime={[10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]}
                >
                    {({ remainingTime }) => remainingTime}
                </CountdownCircleTimer>
            </div>
            <h1 class="text-3xl font-bold py-8">We appreciate your patience</h1>
            <p class="text-1xl pb-8 px-12 font-small">Not Liking Ads, Contact link author for Premium links</p>
            <button class="bg-gradient-to-r from-purple-400 to-blue-500 hover:from-pink-500 hover:to-orange-500 text-white font-semibold px-6 py-3 rounded-md mr-6">
            Get Premium 
            </button>
            <p class="text-1xl pb-8 px-12 font-small my-5">Feels unexpected behaviour, Ads should not be here...</p>
            <button class="bg-gradient-to-r from-red-400 to-red-500 hover:from-red-500 hover:to-red-500 text-white font-semibold px-9 py-3 rounded-md">
            Contact Us
            </button>
            <div class="ad-zone ad-space ad-unit textads banner-ads banner_ads"> 
            </div>
            {wait_and_redirect()}
            </div>
            </div>
            </div>
        </div>
    );
}

export default AdsInterceptor;