// src/App.js
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate
} from 'react-router-dom';
import Home from './pages/home';
import Products from './pages/product';
import Pricing from './pages/pricing';
import Contact from './pages/contact';
import About from './pages/about';
import Login from './pages/login';
import Signup from './pages/signup';
import ForgotPassword from './pages/ForgotPassword';
import FourOFour from './pages/404_page';
import PrivacyPolicy from './pages/privacy_policy';
import DashBoard from './pages/dashboard/dashboard';
import Links from './pages/dashboard/links';
import FourZeroFourLink from './pages/404_link';
import Protected from "./Protected";
import SidebarDrawer from './components/Sidebar/SidebarDrawer';
import QrPages from './pages/QrPages';
import CommingSoon from './pages/commingSoon';
import  { DataProvider } from './DataContext';

const AppLayout = () => {
    let token = localStorage.getItem('token');
    let isAuthenticated;
    if(token){
        isAuthenticated = true;
    }else{
        isAuthenticated = false;
    }

  return (
      <div>
        <SidebarDrawer />
        <DataProvider>
      <Routes>
        <Route exact path='/' element= {<Home />} />
        <Route exact path='/products' element= {<Products />} />
        {/* <Route exact path='/pricing' element= {<Pricing />} /> */}
        <Route exact path='/contact' element= {<Contact />} />
        <Route exact path='/about' element= {<About />} />
        <Route exact path='/login' element = {
          <Protected isLoggedIn={!isAuthenticated}>
            <Login />
          </Protected>
        } />
          <Route exact path='/qr-code' element = {
            <Protected isLoggedIn={isAuthenticated}>
              <QrPages />
            </Protected>
          } />
          <Route exact path='/comming-soon' element = {
            <Protected isLoggedIn={isAuthenticated}>
              <CommingSoon />
            </Protected>
          } />
        <Route exact path='/signup' element = {<Signup />} />
        <Route exact path='/forgot' element = {<ForgotPassword />} />
        <Route exact path='/pages/privacy' element = {<PrivacyPolicy />} />
        <Route exact path='/dashboard' element={
          <Protected isLoggedIn={isAuthenticated}>
            <DashBoard />
          </Protected>
        } />
        <Route exact path='/dashboard/links' element={
          <Protected isLoggedIn={isAuthenticated}>
            <Links />
          </Protected>
        } />
        <Route exact path='/link/404' element = {<FourZeroFourLink />} />
        <Route exact path='/404' element = {<FourOFour />} />
        <Route path='*' element={<Navigate to="/app/404" replace />} />
      </Routes>
      </DataProvider>
      </div>
  );
}

export default AppLayout;
