import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Box, ThemeProvider, createTheme } from '@mui/material';
import HistoryModal from '../Modal/HistoryModal';
import ToolbarComponent from "./ToolBar";
const theme = createTheme();
  function Header() {
    const [openToolbar, setOpenToolbar] = useState(false)
    const [showLogin, setShowLogin] = React.useState(true);
    useEffect(() =>{
        let token = localStorage.getItem('token');
        if(token){
            setShowLogin(false)
        }
      }, [])

    return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" sx={{ backgroundColor: '#17143F', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.08), 0px 1px 10px 0px rgba(0,0,0,0.05)' }}>
        <Container>
          <Toolbar>
            <Box display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ml:0, width:"100%", p:0}}>
            {showLogin && (
              <Box display="flex" alignItems="center" sx={{display: { xs: 'none', sm: 'none', md:'block', lg:'block' }}} >
                <nav>
                  <Link to="/app" style={{ marginRight: '20px' }}>
                      Home
                  </Link>
                  <Link to="/app/products" style={{ marginRight: '20px' }}>
                      Products
                  </Link>
                  <Link to="/app/contact" style={{ marginRight: '20px' }}>
                      Contact
                  </Link>
                  <Link to="/app/about" style={{ marginRight: '20px' }}>
                      About
                  </Link>
                  </nav>
                </Box>
              )}
              {showLogin && (
                <Box sx={{display: { xs: 'block', sm: 'block', md:'none', lg:'none' }}}>
                  <ToolbarComponent openDrawerHandler={openToolbar} />
                </Box>
              )}
              {showLogin ? (
                <Box sx={{ display: 'flex', position:'fixed', ml: {xs: '25%', sm: '30%', md:'32%', lg:'20%'} }} display="flex"
                justifyContent="center"
                alignItems="center"
                >
                <Link to="/app" style={{ display: 'flex', alignItems: 'center', color: 'inherit', textDecoration: 'none' }}>
                  <img src="https://ik.imagekit.io/surls/snip_logo/apple-touch-icon.png?updatedAt=1697361926884" style={{ height: 40, marginTop: 10, marginBottom: 10 }} alt="snip-url-logo" />
                  <Typography variant="h6" sx={{ marginLeft: 2 }}>
                    SnipUrl.in
                  </Typography>
                </Link>
              </Box>
              ) : 
              <Box sx={{ display: 'flex', position:'fixed', ml: {xs: '10%', sm: '25%', md:'30%', lg:'20%'} }} display="flex"
                justifyContent="center"
                alignItems="center"
                >
                <Link to="/app/dashboard" style={{ display: 'flex', alignItems: 'center', color: 'inherit', textDecoration: 'none' }}>
                  <img src="https://ik.imagekit.io/surls/snip_logo/apple-touch-icon.png?updatedAt=1697361926884" style={{ height: 40, marginTop: 10, marginBottom: 10 }} alt="snip-url-logo" />
                  <Typography variant="h6" sx={{ marginLeft: 2 }}>
                    SnipUrl.in
                  </Typography>
                </Link>
              </Box>
              }
              {showLogin && (                
                <Box display="flex" alignItems="center"  sx={{display: { xs: 'none', sm: 'none', md:'block', lg:'block' },}}>
                    <Button component={Link} to="/app/login" variant="contained" sx={{ backgroundColor: '#4338CA', color: 'white', marginLeft: 2, '&:hover': { backgroundColor: '#6366F2' } }}>
                        Login
                    </Button>
                    <Button component={Link} to="/app/signup" variant="contained" sx={{ backgroundColor: '#4338CA', color: 'white', marginLeft: 2, '&:hover': { backgroundColor: '#6366F2' } }}>
                        Signup
                    </Button>
                    <Button variant="contained" sx={{ backgroundColor: '#4338CA', color: 'white', marginLeft: 2, '&:hover': { backgroundColor: '#6366F2' } }}>
                        <HistoryModal />
                    </Button>
                </Box>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
    );
  }

  export default Header;
     
