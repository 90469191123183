import React, {useEffect, useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledEngineProvider } from '@mui/material/styles';


export default function Spinner() {
  const [open, setOpen] = useState(true);
  useEffect(() => {
    setOpen(true);
 }, []);

  return (
    <StyledEngineProvider injectFirst>
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
    </StyledEngineProvider>
  );
}
