import React from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Box, Grid} from "@mui/material";

function FourOFour() {
  return (
    <Grid container spacing={2}
      px={6}
      py={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} md={6} lg={6} textAlign="left">
        <Typography variant="body2" color="primary" gutterBottom>
          404 error
        </Typography>
        <Typography variant="h4" mt={3} fontWeight="bold" color="text.primary">
          Page not found
        </Typography>
        <Typography variant="body1" mt={4} color="text.secondary">
          Sorry, the page you are looking for doesn't exist. Here are some
          helpful links:
        </Typography>

        <Box mt={6} display="flex" gap={3}>
          <Button
            component={Link}
            to="/app"
            variant="contained"
            size="large"
            sx={{
              backgroundColor: "#2196f3",
              "&:hover": { backgroundColor: "#1565c0" },
            }}
          >
            Take me home
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12} md={6} lg={6} mt={{ xs: 2, lg: 0 }}>
        <img
          src="https://merakiui.com/images/components/illustration.svg"
          alt=""
          style={{
            width: "100%",
            maxWidth: "lg",
            margin: "auto",
            display: "block",
          }}
        />
      </Grid>
    </Grid>
  );
}

export default FourOFour;
