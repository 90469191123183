import React from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function DashBoardCardAnalytics(props) {
    const navigate = useNavigate();
  const { total_links, active_links, inactive_links} = props;

  const navigateLinks = () => {
    navigate('/app/dashboard/links');
  }
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <CardContent sx={{ textAlign: 'center'}}>
          <Typography  variant="h5" component="div" sx={{backgroundColor:'#3B82F6', color:'#fff'}}>
            Analytics
          </Typography>
          <Typography sx={{ fontSize: 18, mt:2 }} color="text.primary" gutterBottom>
            Total Links Created: {total_links}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Active Links: {active_links}
          </Typography>
          <Typography variant="body2">
            Inactive Links: {inactive_links}
          </Typography>
          <Typography sx={{ textAlign: 'right', px:5 }}>
            <Button size="small" onClick={navigateLinks}>View More</Button>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}