import React, { useState, useEffect } from 'react';
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import loginInterceptors from '../config/loginHTTPs';
import { Grid } from '@mui/material';
import Cookies from 'universal-cookie';
import OTPVerify from '../components/OTPVerify/OTPVerify';
import Footer from '../components/HeaderFooter/Footer';
import Spinner from '../components/Spinner/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, seIsVerified] = useState(false)

  useEffect(() => {
    let storedCredentials = '';
    if(localStorage.getItem('savedCredentials') && localStorage.getItem('savedCredentials') != undefined){
      storedCredentials = JSON.parse(decryptData(localStorage.getItem('savedCredentials')));
    }
    if (storedCredentials) {      
      const { email, password, rememberMe } = storedCredentials;
      setFormData({ email, password, rememberMe });
    }
  }, []);

  const showToastMessageError = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;
    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    let data = new FormData();
    data.append('grant_type', '');
    data.append('scope', '');
    data.append('client_id', '');
    data.append('client_secret', '');
    data.append('username', formData.email);
    data.append('password', formData.password);
    setIsLoading(true);
    try {
      const response = await loginInterceptors.post('/auth/login', data); 
      if(response.data){
        if(response?.data?.access_token){
          if(response?.data?.verified){
            localStorage.setItem('token', response?.data?.access_token);
            navigate('/app/dashboard');
            window.location.reload();
          } else {
            let d = new Date();
            d.setTime(d.getTime() + (60*60*1000));
            const cookies = new Cookies();
            cookies.set('user_token',response?.data?.access_token,{path: "/", expires: d});
            seIsVerified(true);
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      showToastMessageError(error?.response?.data?.detail)
    }
    setIsLoading(false);
    if (formData.rememberMe) {
      const savedCredentials = JSON.stringify({
        email: formData.email,
        password: formData.password,
        rememberMe: formData.rememberMe,
      });
      localStorage.setItem('savedCredentials', encryptData(savedCredentials));
    } else {
      localStorage.removeItem('savedCredentials');
    }
  };

  const encryptData = (text) => {
    const secretPass = 'SnipUrlEncryptionKey=loginKeyEncrypyt__jkdshfjdhfjk'
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();
    return data;
  };

  const decryptData = (text) => {
    const secretPass = 'SnipUrlEncryptionKey=loginKeyEncrypyt__jkdshfjdhfjk'
    const bytes = CryptoJS.AES.decrypt(text, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  };

    return (
      <div>
        {isLoading == true ? <Spinner />: <div></div>}
        <section className="bg-blueGray-50">
  {!isVerified && (
  <div className="w-full lg:w-4/12 px-4 mx-auto pt-6">
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
      <div className="flex-auto px-4 lg:px-10 py-10">
        <div className="text-blueGray-400 text-center mb-3 font-bold">
          <small>sign in with credentials</small>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="relative w-full mb-3">
            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Email</label>
            <input type="email"  name="email" value={formData.email} onChange={handleChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Email"/>
          </div>
          <div className="relative w-full mb-3">
            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Password</label>
            <input type="password"  name="password" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Password" value={formData.password} onChange={handleChange}/>
          </div>
          <div>
            <label className="inline-flex items-center cursor-pointer">
              <input id="customCheckLogin" type="checkbox" className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150" checked={formData.rememberMe} onChange={handleChange} name="rememberMe" />
              <span className="ml-2 text-sm font-semibold text-blueGray-600">Remember me</span></label>
          </div>
          <div style={{textAlign: "right"}}>
          <Link to="/app/forgot" className="text-blue-500 hover:text-blue-800" target="_self">Forgot Password</Link>
          </div>
          <div className="text-center mt-6">
            <button className="bg-blue-500 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="submit"> Sign In </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  )}
    {isVerified && (
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item sx={{width:1/2}}>
          <OTPVerify emailId={formData.email} otpType={'account_verification'}/>
          </Grid>
        </Grid>
    )}
  <footer className="relative pt-8 pb-6 mt-2">
    <div className="container mx-auto px-2">
      <div className="flex flex-wrap items-center md:justify-between justify-center">
        <div className="w-full md:w-6/12 px-4 mx-auto text-center">
          <div className="text-sm text-blueGray-500 font-semibold py-1">
            Need Help <Link to="/app/contact" className="text-blueGray-500 hover:text-gray-800" target="_blank">Yes, please</Link>.
          </div>
        </div>
      </div>
    </div>
    <ToastContainer />
      </footer>
        </section>
      <Footer />
      </div>
    );

}

export default Login;