import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { TextField, Button, Typography, Box, Grid } from '@mui/material';
import https from '../../config/https';
import Spinner from '../Spinner/Spinner';
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';


const OTPVerify = ({emailId, otpType}) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [isResent, setIsResent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [remainingTime, setRemainingTime] = useState(30);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let intervalId;
    if (remainingTime > 0) {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    if (remainingTime === 0) {
        setIsResent(true);
      }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isResent, remainingTime]);

  const handleOtpChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value.length <= 6) {
      setOtp(value);
      setIsOtpValid(value.length === 6);
      setErrorMessage('');
    } else {
      setErrorMessage('Please enter a valid 6-digit OTP.');
    }
  };

  const handleResendOtp = async() => {
    setIsLoading(true);
    try {
      const response = await https.post(`/auth/otp/resend?email=${emailId}&otp_type=${otpType}`); 
      if(response?.status === 200){
        setIsResent(true);
        setOtp('');
        setIsOtpValid(false);
        setErrorMessage('');
        setIsResent(false);
        setRemainingTime(30);
        showToastMessageSuccess('OTP Resend Successfully.');
      }
      setIsLoading(false);
    } catch (error) {
        showToastMessageError('Please enter a valid 6-digit OTP.');
    }
    setIsLoading(false);
  };

  const showToastMessageError = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastMessageSuccess = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleSubmit = async() => {
    if (isOtpValid) {
      setIsLoading(true);
      try {
        const response = await https.post(`auth/otp/verify?otp=${otp}&email=${emailId}&otp_type=${otpType}`); 
        if(otpType === 'account_verification'){
          if(response?.data){
            const cookies = new Cookies();
            let token = cookies.get('user_token');
            localStorage.setItem('token', token);
            navigate('/app/dashboard');
            window.location.reload();
          }
        }else{
          navigate('/app/login');
          console.log('password verify successfully');
        }
        setIsLoading(false);
      } catch (error) {
          showToastMessageError('Please enter a valid 6-digit OTP.');
      }
      setIsLoading(false);
      setErrorMessage('');
    } else {
      setErrorMessage('Please enter a valid 6-digit OTP.');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
    {isLoading === true ? <Spinner />: <div></div>}
      <Typography variant="h4" gutterBottom>
        OTP Verification
      </Typography>
      <TextField
        type="text"
        label="Enter 6-digit OTP"
        variant="outlined"
        inputProps={{ maxLength: 6 }}
        value={otp}
        onChange={handleOtpChange}
        InputProps={{
          style: { border: 'none' },
        }}
        error={!!errorMessage}
        helperText={errorMessage}
        style={{ marginBottom: 16, width: '80%' }}
      />
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleResendOtp}
            disabled={!isResent}
          >
            {remainingTime > 0
              ? `Resend OTP (${remainingTime}s)`
              : 'Resend OTP'}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!isOtpValid}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <ToastContainer />
    </Box>
  );
};

export default OTPVerify;
