import {useState} from 'react'
import Cookies from 'universal-cookie';
import https from '../../config/https';
import Spinner from '../../components/Spinner/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {Grid, Typography, Box, Card} from '@mui/material'

function InputForLinks(props) {

  const checkCookieExists = (name) => {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else
    {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
        end = dc.length;
        }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
} 

  const [input_long_url, set_input_long_url] = useState('');
  const [alias_value, set_alias_value] = useState('');
  const [alias_error_message, set_alias_error_message] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handle_input = (event) => {
    set_input_long_url(event.target.value);
  }

  const handle_alias = (event) => {
    set_alias_value(event.target.value);
  }

  const isUrlValid = (userInput) => {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
        return false;
    else
        return true;
}

  const handle_input_form = (event) =>{
    event.preventDefault();
    set_alias_error_message('');
    let isUrlValid_response = isUrlValid(input_long_url);
    if (isUrlValid_response === false) {
      alert("Please enter a valid url !");
      return null;
    }

    if(alias_value.length > 30){
      set_alias_error_message("The Alias must not be greater than 30 characters.");
      return;
    }
    
    if(alias_value && alias_value.length <= 30){
      available_alias();
      return;
    } else {
      set_input_long_url("");
      make_shorten_api_call();
    }
  }

  const available_alias = async () => {
    let data = {
      alias: alias_value
    };
    setIsLoading(true);
    try {
      const response = await https.post('/dash/alias-check', data); 
      if(!response.data.alias_exist){
        make_shorten_api_call()
      }
      else{
        set_alias_error_message("Alias is not available.");
      }
      setIsLoading(false);
    } catch (error) {
    }
    setIsLoading(false);
  }

  const showToastMessageError = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const make_shorten_api_call = async () => {
      let token = localStorage.getItem('token');
      let url = '';
      let qr_code = true;
      if(token){
        url='/dash/links';
        qr_code = false;
      }else{
        url='/short-url';
        qr_code = true;
      }
      let data = {
        url: input_long_url,
        alias: alias_value,
        qr_code: qr_code
      };
      setIsLoading(true);
      try {
        const response = await https.post(url, data); 
        if(response.data){
          const response_data = {
            original_url : input_long_url,
            short_url : response.data.shorten_url,
            qr_code : response.data.qr_code_path
          }        
        }
        window.location.reload();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showToastMessageError(error?.response?.data?.detail)
      }
      setIsLoading(false);
  }

  return (
    <Box sx={{ flexGrow: 1, overflow: 'hidden', p:1}} >
        <Card variant="outlined" sx={{ p:2 }}>
      {isLoading && <Spinner />}
      <Grid container>
        <Grid item xs={12} md={12} lg={12} align = "center" justify = "center" alignItems = "center">
            <Typography sx={{ fontWeight: 700, m: 1 }}>Short Really Long URL</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
        <form onSubmit={handle_input_form}>
            <Grid item sx={{my:2}}>
                <TextField
                    type="url"
                    required
                    value={input_long_url}
                    onChange={handle_input}
                    id="search-form-price"
                    variant="outlined"
                    fullWidth
                    placeholder="Your long URL"
                />
            </Grid>
            <Grid item sx={{my:2}}>
                <TextField
                    type="text"
                    value={alias_value}
                    onChange={handle_alias}
                    id="alias_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter alias(optional)"
                />
            </Grid>
            <Grid item sx={{my:2}}>
              <Typography sx={{ color: 'error.main' }} style={{ fontSize: 14 }}>
                {alias_error_message}
              </Typography>
            </Grid>
            <Grid item sx={{my:2}} align = "center" justify = "center" alignItems = "center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="rounded-lg"
                  size='medium'
                >
                  Shorten URL
                </Button>
            </Grid>
        </form>
        </Grid>
      </Grid>
      </Card>
      <ToastContainer />
    </Box>
  );
}

export default InputForLinks;
