import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HistoryModal from '../Modal/HistoryModal';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import HistoryIcon from '@mui/icons-material/History';
import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const ToolbarComponent = ({ openDrawerHandler }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem component={Link} to="/app/login" onClick={handleMenuClose}>
        <IconButton aria-label="show 4 new mails" color="inherit">
            <LoginIcon/>
        </IconButton>
        <p>Login</p>
      </MenuItem>
      <MenuItem component={Link} to="/app/signup" onClick={handleMenuClose}>
        <IconButton aria-label="Signup" color="inherit">
            <PersonAddAltIcon />
        </IconButton>
        <p>Signup</p>
      </MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>
        <IconButton
          aria-label="History of Urls"
        >
          <HistoryIcon />
        </IconButton>
        <p style={{padding:0}}><HistoryModal /></p>
      </MenuItem> */}
      <MenuItem component={Link} to="/app" onClick={handleMenuClose}>
        <IconButton
          aria-label="Home"
        >
          <HomeIcon />
        </IconButton>
        <p>Home</p>
      </MenuItem>
      <MenuItem component={Link} to="/app/products" onClick={handleMenuClose}>
        <IconButton
          aria-label="Product"
        >
          <CategoryIcon />
        </IconButton>
        <p>Product</p>
      </MenuItem>
      <MenuItem component={Link} to="/app/contact" onClick={handleMenuClose}>
        <IconButton
          aria-label="Contact"
        >
          <ContactPageIcon />
        </IconButton>
        <p>Contact</p>
      </MenuItem>
      <MenuItem component={Link} to="/app/about" onClick={handleMenuClose}>
        <IconButton
          aria-label="account of current user"
        >
          <InfoIcon />
        </IconButton>
        <p>About</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <div>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
      {renderMobileMenu}
    </div>
  );
};

export default ToolbarComponent;
