import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import EditLinks from '../../components/EditLinks/EditLinks'
import Spinner from '../../components/Spinner/Spinner';
import https from '../../config/https';
import { ToastContainer, toast } from 'react-toastify';
import InputForLinks from '../../components/InputForLinks/InputForLinks';
  
function Links(){
    const [isLoading, setIsLoading] = useState(false);
    const[urlData, setUrlData] = useState([])
    useEffect(()=>{
        getDashBoardLinks()
      },[])
  
      const getDashBoardLinks = async() => {
        setIsLoading(true);
        try {
          const response = await https.get(`/dash/links`); 
          if(response?.data){
            setUrlData(response.data)
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          showToastMessageError("No links found! Create a couple of links");
        }
      }
      const showToastMessageError = (msg) => {
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      };

    return (
        <>
            {isLoading === true ? <Spinner />: <div></div>}
            <Grid container spacing={1} sx={{display: "flex", width: "auto", ml:2 }}>
              <Grid item xs={12} md={4} lg={4} sx={{px: 2, mt:0}}>
                <InputForLinks />
              </Grid>
              <Grid item xs={12} md={8} lg={8} sx={{px: 2}}>
                <EditLinks data={urlData}  />
              </Grid>
            </Grid>
            <ToastContainer />
        </>
    );
}


export default Links;